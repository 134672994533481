import React from "react";

const Loading = () => (
    <div className="absolute inset-0 h-screen w-full flex flex-col items-center justify-center">
        <div className="flex items-center justify-center fa-fw fa-5x">
            <i className="fas fa-spinner fa-spin fa-fw"></i>
        </div>
        <div className="text-sm mt-4">Loading...</div>
    </div>
);

export default Loading;
