import React, { useContext } from "react";
import { CurrentUserContext, CurrentUserProvider } from "../providers/auth";
import { graphql, useStaticQuery } from "gatsby";
import axios from "axios";

import sideBar from "../assets/payment/sideBar.json";

export const PlanContext = React.createContext(null);

export const PlanProvider = ({ children }) => {
    const { CPAS_API_SERVICE_URI } = useStaticQuery(graphql`
        query PlanResourceAccessProviderQuery {
            site {
                siteMetadata {
                    CPAS_API_SERVICE_URI
                }
            }
        }
    `).site.siteMetadata;

    const currentUser = useContext(CurrentUserContext);
    const planObj = {
        getPlans: () => axios.get(`${CPAS_API_SERVICE_URI}/plan/list`),
        getOrder: (orderId) => {
            return currentUser.getIdToken().then((idToken) => {
                if (idToken) {
                    return axios.get(
                        `${CPAS_API_SERVICE_URI}/account/order/${orderId}`,
                        {
                            headers: {
                                Authorization: "Bearer " + idToken,
                            },
                        }
                    );
                }
            });
        },
        getCurrentStoredValue: () => {
            return currentUser.getIdToken().then((idToken) => {
                if (idToken) {
                    return axios.get(
                        `${CPAS_API_SERVICE_URI}/account/stored-value/current`,
                        {
                            headers: {
                                Authorization: "Bearer " + idToken,
                            },
                        }
                    );
                }
            });
        },
        getVCpu: () =>
            axios.get(`${CPAS_API_SERVICE_URI}/plan/extra/list?type=extra_cpu`),
        getMemory: () =>
            axios.get(`${CPAS_API_SERVICE_URI}/plan/extra/list?type=extra_mem`),
        getStorage: () =>
            axios.get(
                `${CPAS_API_SERVICE_URI}/plan/extra/list?type=extra_storage`
            ),
        getCategories: () => sideBar,
        getCurrentSubscriptions: () =>
            currentUser.getIdToken().then((idToken) => {
                if (idToken) {
                    return axios.get(
                        `${CPAS_API_SERVICE_URI}/account/subscription/active`,
                        {
                            headers: {
                                Authorization: "Bearer " + idToken,
                            },
                        }
                    );
                }
            }),
        getNonExpiredSubscriptions: () =>
            currentUser.getIdToken().then((idToken) => {
                if (idToken) {
                    return axios.get(
                        `${CPAS_API_SERVICE_URI}/account/subscription/non-expired`,
                        {
                            headers: {
                                Authorization: "Bearer " + idToken,
                            },
                        }
                    );
                }
            }),
        getInProgressOrders: () =>
            currentUser.getIdToken().then((idToken) => {
                if (idToken) {
                    return axios.get(
                        `${CPAS_API_SERVICE_URI}/account/order/plan-in-progress`,
                        {
                            headers: {
                                Authorization: "Bearer " + idToken,
                            },
                        }
                    );
                }
            }),
        checkCoupon: ({ couponCode, userToken }) =>
            axios.get(`${CPAS_API_SERVICE_URI}/payment/coupon/${couponCode}`, {
                headers: {
                    Authorization: "Bearer " + userToken,
                },
            }),
        subscribe: ({
            planID,
            priceDuration,
            priceDurationUnit,
            quantity,
            couponCode,
            userToken,
        }) => {
            let param = {
                plan_id: planID,
                price_duration: priceDuration,
                price_duration_unit: priceDurationUnit,
                is_renew: false,
            };
            if (!!quantity) {
                param.quantity = parseInt(quantity);
            }
            if (!!couponCode) {
                param.coupon_code = couponCode;
            }
            return axios.post(
                `${CPAS_API_SERVICE_URI}/payment/subscribe`,
                param,
                { headers: { Authorization: "Bearer " + userToken } }
            );
        },
        extend: ({
            planID,
            priceDuration,
            priceDurationUnit,
            quantity,
            couponCode,
            userToken,
        }) => {
            let param = {
                plan_id: planID,
                price_duration: priceDuration,
                price_duration_unit: priceDurationUnit,
            };
            if (!!quantity) {
                param.quantity = parseInt(quantity);
            }
            if (!!couponCode) {
                param.coupon_code = couponCode;
            }

            return axios.post(`${CPAS_API_SERVICE_URI}/payment/extend`, param, {
                headers: { Authorization: "Bearer " + userToken },
            });
        },

        upgradeCurrentSubscription: ({
            planID,
            quantity,
            userToken,
            couponCode,
        }) => {
            let param = { plan_id: planID, quantity: parseInt(quantity) };
            if (!!couponCode) {
                param.coupon_code = couponCode;
            }
            return axios.post(
                `${CPAS_API_SERVICE_URI}/payment/upgrade/current`,
                param,
                { headers: { Authorization: "Bearer " + userToken } }
            );
        },
        payOrder: (planID, userToken) => {
            return axios.post(
                `${CPAS_API_SERVICE_URI}/account/order/${planID}/payment/pay`,
                {},
                { headers: { Authorization: "Bearer " + userToken } }
            );
        },
        cancelOrder: (planID, userToken) => {
            return axios.put(
                `${CPAS_API_SERVICE_URI}/account/order/${planID}/payment/cancel`,
                {},
                { headers: { Authorization: "Bearer " + userToken } }
            );
        },
    };

    return (
        <CurrentUserProvider>
            <PlanContext.Provider value={planObj}>
                {children}
            </PlanContext.Provider>
        </CurrentUserProvider>
    );
};
