import moment from "moment";

export const formatDate = (d) => moment(d).format("YYYY-MM-DD");

export const formatPrice = (priceCent, priceUnit) =>
    typeof priceCent === "number"
        ? priceUnit +
          " " +
          (priceCent / 100).toLocaleString("en-US", {
              minimumFractionDigits: 2,
          })
        : priceUnit + " " + priceCent;

// For example, per "GB" and per "1,000 GB"
export const formatRate = (rate, rateUnit) =>
    rate === 1
        ? rateUnit
        : rate.toLocaleString("en-US", {
              minimumFractionDigits: 2,
          }) +
          " " +
          rateUnit;
