import React, { useContext, useState, useEffect } from "react";

import { formatDate } from "../../helpers/formatter";

import { CurrentUserContext } from "../../providers/auth";
import { PlanContext, PlanProvider } from "../../providers/plan";

import Layout from "../../components/layout";
import Seo from "../../components/seo";
import Loading from "../../components/loading";
import { ErrorAlert } from "../../components/errorAlert";
import { Router } from "@reach/router";
//import PlanDetail from "../../components/planDetail";
import { Link } from "gatsby";

const CurrentSubscription = () => (
    <Layout>
        <PlanProvider>
            <Router>
                <CurrentPlan path="purchase/currentSubscription" />
            </Router>
        </PlanProvider>
    </Layout>
);

export default CurrentSubscription;

const CurrentPlan = () => {
    const currentUser = useContext(CurrentUserContext);
    const planContext = useContext(PlanContext);

    const [currentSubscriptions, setCurrentSubscriptions] = useState(null);
    const [currentStoredValues, setCurrentStoredValues] = useState(null);
    const [showSignInAgain, setShowSignInAgain] = useState(false);

    const [errorMessage, setErrorMessage] = useState(null);

    useEffect(() => {
        // var currentPlanDom = <></>;
        window.sessionStorage.removeItem("redirectAfterLogin");
        currentUser.getIdToken().then((idToken) => {
            if (idToken) {
                planContext
                    .getCurrentSubscriptions()
                    .then((currentPlan) => {
                        setCurrentSubscriptions(currentPlan.data.subscriptions);
                    })
                    .catch((error) => {
                        console.log(error);
                        setErrorMessage(
                            "Internal server error. Please refresh and try again."
                        );
                    });
                planContext
                    .getCurrentStoredValue()
                    .then((currentStoredValues) => {
                        setCurrentStoredValues(currentStoredValues.data);
                    })
                    .catch((error) => {
                        console.log(error);
                        setErrorMessage(
                            "Internal server error. Please refresh and try again."
                        );
                    });
            } else {
                setShowSignInAgain(true);
            }
        });
    }, [currentUser, planContext]);

    return (
        <>
            <Seo title="My Subscription" />
            <div className="flex flex-row items-stretch bg-grey-light h-12">
                <div className="flex flex-row items-center flex-1 ml-4">
                    My Subscription
                </div>
            </div>

            <ErrorAlert errorMessage={errorMessage}></ErrorAlert>

            {currentSubscriptions && currentStoredValues && (
                <div className="md:w-4/5 lg:w-3/5 mx-auto">
                    <BasicPlanDetail
                        subscriptions={currentSubscriptions}
                    ></BasicPlanDetail>
                    <StoredValueDetail
                        storedValues={currentStoredValues}
                    ></StoredValueDetail>
                    <ExtraPlanDetail
                        subscriptions={currentSubscriptions}
                    ></ExtraPlanDetail>
                    <hr className="my-4" />
                    <div className={"flex flex-row-reverse"}>
                        <Link
                            className={"primary btn"}
                            to="/purchase/purchase"
                            state={{ category: "plan" }}
                        >
                            Change Plan
                        </Link>
                    </div>
                </div>
            )}

            {!showSignInAgain &&
            (!currentSubscriptions || !currentStoredValues) ? (
                <Loading />
            ) : (
                ""
            )}
        </>
    );
};

const sorterByStartTime = (a, b) => {
    if (a.start_at < b.start_at) {
        return -1;
    }
    if (a.start_at > b.start_at) {
        return 1;
    }
    return 0;
};

const BasicPlanSummary = ({ subscriptions }) => {
    const basicSubscription = subscriptions["basic"].sort(sorterByStartTime)[0];

    let basicEffectivePeriod = "";

    if (
        basicSubscription.start_at === undefined &&
        basicSubscription.end_at === undefined
    ) {
        basicEffectivePeriod = "Unlimited";
    } else {
        basicEffectivePeriod =
            formatDate(new Date(basicSubscription.start_at)) +
            " to " +
            formatDate(new Date(basicSubscription.end_at));
        if (basicSubscription.is_renew) {
            basicEffectivePeriod += " (Auto-renew)";
        }
    }

    return (
        <>
            <div className="text-sm font-bold">
                Your current plan: {basicSubscription.plan.display_name}
            </div>
            <div className="text-sm font-bold">
                Effective period: {basicEffectivePeriod}
            </div>
        </>
    );
};

const BasicPlanDetail = ({ subscriptions }) => {
    const basicPlanDetailDisplayOrder = [
        {
            key: "mesh_gen_param",
            children: [{ key: "num_cells_limit" }, { key: "mesh_gen_limit" }],
        },
        {
            key: "real_sim_param",
            children: [
                { key: "gigacellstep_limit" },
                { key: "num_hours_limit" },
                { key: "real_sim_limit" },
            ],
        },
        {
            key: "vis_param",
            children: [
                { key: "jupyter_mem_limit" },
                { key: "jupyter_cpu_limit" },
            ],
        },
        {
            key: "utility_param",
            children: [{ key: "storage_limit" }],
        },
    ];

    const basicSubscription = subscriptions["basic"].sort(sorterByStartTime)[0];

    const bgList = ["bg-lightgreen1", "bg-lightgreen3"];

    const jobDomList = basicPlanDetailDisplayOrder.map(
        (firstOrder, jobIndex) => {
            const rowIndex = jobIndex % bgList.length;
            const bgColor = bgList[rowIndex];

            const firstOrderKey = firstOrder.key;
            return firstOrder.children.map((secondOrder, paramIndex) => {
                const secondOrderKey = secondOrder.key;
                const data = [];
                data.push({
                    planId: basicSubscription.plan_id,
                    value:
                        basicSubscription.plan.detail[firstOrderKey][
                            secondOrderKey
                        ].parameter_value,
                    valueUnit:
                        basicSubscription.plan.detail[firstOrderKey][
                            secondOrderKey
                        ].parameter_value_unit,
                });

                const cellList = [];

                if (paramIndex === 0) {
                    cellList.push(
                        <td
                            className={
                                bgColor + " text-sm p-4 border-2 border-white"
                            }
                            rowSpan={firstOrder.children.length}
                            key="plan.detail.header"
                        >
                            {
                                basicSubscription.plan.detail[firstOrderKey]
                                    .display_name
                            }
                        </td>
                    );
                }

                cellList.push(
                    <td
                        className={
                            bgColor + " text-sm p-4 border-2 border-white"
                        }
                        key={"plan.detail.item"}
                    >
                        {
                            basicSubscription.plan.detail[firstOrderKey][
                                secondOrderKey
                            ].display_name
                        }
                    </td>
                );
                cellList.push(
                    <td
                        className={
                            bgColor + " text-sm p-4 border-2 border-white"
                        }
                        key={"plan.detail.value"}
                    >
                        {typeof basicSubscription.plan.detail[firstOrderKey][
                            secondOrderKey
                        ].parameter_value === "number"
                            ? basicSubscription.plan.detail[firstOrderKey][
                                  secondOrderKey
                              ].parameter_value.toLocaleString("en-US")
                            : basicSubscription.plan.detail[firstOrderKey][
                                  secondOrderKey
                              ].parameter_value}{" "}
                        {
                            basicSubscription.plan.detail[firstOrderKey][
                                secondOrderKey
                            ].parameter_value_unit
                        }
                    </td>
                );

                return (
                    <tr
                        className={bgColor + " p-4 border-2 border-white"}
                        key={firstOrderKey + "." + secondOrderKey}
                    >
                        {cellList}
                    </tr>
                );
            });
        }
    );

    return (
        <>
            <h1 className="text-2xl">Basic plan detail</h1>
            <BasicPlanSummary subscriptions={subscriptions}></BasicPlanSummary>
            <table className="w-full">
                <thead>
                    <tr>
                        <th
                            colSpan="2"
                            className="bg-grey-lightest text-sm p-4 border-2 border-white"
                        >
                            Item
                        </th>
                        <th className="bg-grey-lightest text-sm p-4 border-2 border-white">
                            Limit
                        </th>
                    </tr>
                </thead>
                <tbody>{jobDomList}</tbody>
            </table>
        </>
    );
};

const ExtraPlanDetail = ({ subscriptions }) => {
    const vcpuExtraPlanDetailDisplayOrder = {
        jobName: "vis_param",
        paramName: "jupyter_cpu_limit",
    };

    const memExtraPlanDetailDisplayOrder = {
        jobName: "vis_param",
        paramName: "jupyter_mem_limit",
    };

    const storageExtraPlanDetailDisplayOrder = {
        jobName: "utility_param",
        paramName: "storage_limit",
    };

    const extraVCpuPlan = subscriptions["extra_cpu"].sort(sorterByStartTime)[0];
    const extraMemoryPlan = subscriptions["extra_mem"].sort(
        sorterByStartTime
    )[0];
    const extraStoragePlan = subscriptions["extra_storage"].sort(
        sorterByStartTime
    )[0];

    function extraRow(subscription, index, displayOrder) {
        const plan = subscription.plan;
        const bgColor = index % 2 ? "bg-lightgreen1" : "bg-lightgreen3";
        const jobName = displayOrder.jobName;
        const paramName = displayOrder.paramName;

        let value = "--";

        if (subscription.quantity) {
            value =
                plan.detail[jobName][paramName].parameter_value *
                subscription.quantity;
            if (plan.detail[jobName][paramName].parameter_value_unit) {
                value +=
                    " " + plan.detail[jobName][paramName].parameter_value_unit;
            }
        }

        let effectiveDate = "--";
        if (subscription.start_at && subscription.end_at) {
            effectiveDate =
                formatDate(new Date(subscription.start_at)) +
                " to " +
                formatDate(new Date(subscription.end_at));
        }

        return (
            <tr>
                <td className={bgColor + " text-sm p-4 border-2 border-white"}>
                    {plan.detail[jobName].display_name}
                </td>
                <td className={bgColor + " text-sm p-4 border-2 border-white"}>
                    {plan.detail[jobName][paramName].display_name}
                </td>
                <td className={bgColor + " text-sm p-4 border-2 border-white"}>
                    {value}
                </td>
                <td className={bgColor + " text-sm p-4 border-2 border-white"}>
                    {effectiveDate}
                </td>
            </tr>
        );
    }

    const extraVCpuRow = extraRow(
        extraVCpuPlan,
        1,
        vcpuExtraPlanDetailDisplayOrder
    );
    const extraMemoryRow = extraRow(
        extraMemoryPlan,
        2,
        memExtraPlanDetailDisplayOrder
    );
    const extraStorageRow = extraRow(
        extraStoragePlan,
        3,
        storageExtraPlanDetailDisplayOrder
    );

    return (
        <>
            <h1 className="text-2xl">Extra plan detail</h1>

            <table className="mt-4 w-full">
                <thead>
                    <tr>
                        <th
                            colSpan="2"
                            className="bg-grey-lightest text-sm p-4 border-2 border-white"
                        >
                            Extra item (effective subscription)
                        </th>
                        <th className="bg-grey-lightest text-sm p-4 border-2 border-white">
                            Quantity
                        </th>
                        <th className="bg-grey-lightest text-sm p-4 border-2 border-white">
                            Effective period
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {extraVCpuRow}
                    {extraMemoryRow}
                    {extraStorageRow}
                </tbody>
            </table>
        </>
    );
};

const StoredValueDetail = ({ storedValues }) => {
    if (
        storedValues.gigacellstep_stored_value.length +
            storedValues.io_stored_value.length ===
        0
    ) {
        return <></>;
    }

    const gigacellstepRowHtmlList = storedValues.gigacellstep_stored_value
        .sort((itemA, itemB) => {
            const dateA = new Date(itemA.expire_at);
            const dateB = new Date(itemB.expire_at);
            return dateA.getTime() - dateB.getTime();
        })
        .map((item, idx) => (
            <tr
                className="bg-lightgreen1 p-4 border-2 border-white"
                key={item.resource_name + idx}
            >
                <td className="bg-lightgreen1 text-sm p-4 border-2 border-white">
                    Computational stored value
                </td>
                <td className="bg-lightgreen1 text-sm p-4 border-2 border-white">
                    {item.remark}
                </td>
                <td className="bg-lightgreen1 text-sm p-4 border-2 border-white">
                    {(item.milli_value / 1000).toLocaleString("en-US", {
                        minimumFractionDigits: 3,
                    })}{" "}
                    giga cell-step
                </td>
                <td className="bg-lightgreen1 text-sm p-4 border-2 border-white">
                    {formatDate(item.expire_at)}
                </td>
            </tr>
        ));

    const ioRowHtmlList = storedValues.io_stored_value
        .sort((itemA, itemB) => {
            const dateA = new Date(itemA.expire_at);
            const dateB = new Date(itemB.expire_at);
            return dateA.getTime() - dateB.getTime();
        })
        .map((item, idx) => (
            <tr
                className="bg-lightgreen3 p-4 border-2 border-white"
                key={item.resource_name + idx}
            >
                <td className="bg-lightgreen3 text-sm p-4 border-2 border-white">
                    I/O stored value
                </td>
                <td className="bg-lightgreen3 text-sm p-4 border-2 border-white">
                    {item.remark}
                </td>
                <td className="bg-lightgreen3 text-sm p-4 border-2 border-white">
                    {(item.milli_value / 1000).toLocaleString("en-US", {
                        minimumFractionDigits: 3,
                    })}{" "}
                    GB
                </td>
                <td className="bg-lightgreen3 text-sm p-4 border-2 border-white">
                    {formatDate(item.expire_at)}
                </td>
            </tr>
        ));

    return (
        <>
            <h1 className="text-2xl">Stored value detail</h1>

            <table className="w-full">
                <thead>
                    <tr>
                        <th className="bg-grey-lightest text-sm p-4 border-2 border-white">
                            Item
                        </th>
                        <th className="bg-grey-lightest text-sm p-4 border-2 border-white">
                            Remarks
                        </th>
                        <th className="bg-grey-lightest text-sm p-4 border-2 border-white">
                            Value
                        </th>
                        <th className="bg-grey-lightest text-sm p-4 border-2 border-white">
                            Expiry date
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {gigacellstepRowHtmlList}
                    {ioRowHtmlList}
                </tbody>
            </table>
        </>
    );
};
