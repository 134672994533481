import React from "react";

export const ErrorAlert = ({ errorMessage }) =>
    errorMessage ? (
        <div className="sticky flex pin-t p-2 bg-red text-white px-4">
            {errorMessage}
        </div>
    ) : (
        <></>
    );
